
export function handleResponse(response) {
  if (response.results) {
    return response.results;
  }

  if (response.data) {
    return response.data;
  }
  return response //.json();
}

export function handleError(error) {
  throw error;
}
