import React from "react";
import { GlobalProvider } from "context/GlobalContext";
import Layout from "components/Layout";

export const wrapPageElement = ({ element, props, usersLocale}) => {
  return(
      <Layout {...props}>{element}</Layout>
  );
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider >
    {element}
  </GlobalProvider>
);
