import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";

import "assets/scss/main.scss";

const Layout = ({ children, pageContext }) => {

    useLayoutEffect(() => {
        AOS.init({ once: true });
    }, []);

    return (
        <div className="bg-gray-900 font-semibold"
        >  

            <Helmet>
                <title>Forgery Detection</title>
            </Helmet>
            {children}
        </div>
    );
};

export default Layout;
