import React, { useState, useRef, useEffect } from "react";
import { navigate } from '@reach/router'
import AuthServices from "../services/auth/AuthServices";



const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {

  //const history = useHistory();

  const [lang, setLang] = useState(typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en');
  const [isLoggedIn, setIsLoggedIn] = useState(typeof window !== 'undefined' ? localStorage.getItem('is_logged_in') : false);
  const [accessToken, setAccessToken] =  useState(typeof window !== 'undefined' ? localStorage.getItem('access_token') : null);
  const [refreshToken, setRefreshToken] = useState(typeof window !== 'undefined' ? localStorage.getItem('refresh_token') : null);
  const [userProfile, setUserProfile] = useState(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user_profile')) : null);
  const [subscriptionPlans, setSubscriptionPlans] = useState();
  useEffect(() => {
      AuthServices.paymentIntegration()
      .then((res) => setSubscriptionPlans(res))
      .catch(err => console.log(err));
  },[]);


  const login = (accessToken, refreshToken, userProfile) => {
    localStorage.setItem("is_logged_in", true);
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    localStorage.setItem("user_profile", JSON.stringify(userProfile));

    setIsLoggedIn(true);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    
    setUserProfile(userProfile)

    navigate('/app/dashboard')
    //history.push("/app/dashboard");
  };

  const logout = () => {
    localStorage.removeItem("is_logged_in");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_profile");
    setIsLoggedIn(false);
    setAccessToken(null);
    setRefreshToken(null);
    setUserProfile(null);
  };

  const switchLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setLang(lang);

  }

  return (
    <GlobalContext.Provider
      value={{
        lang, isLoggedIn, accessToken, refreshToken, userProfile,
        switchLanguage, login, logout, subscriptionPlans,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );


};

export default GlobalContext;
export { GlobalProvider };
