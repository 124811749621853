import axios from 'services/core'
import { handleResponse, handleError } from '../response'; 

// Signup
const signup = async (record) => { 
    // console.log("xyz");
    return axios.post(`/api/v1/auth/signup/`, record).then(handleResponse).catch(handleError); 
};


//feedback form
const sendFeedback = async(record) => {
    return axios.post(`/api/v1/user/contact_us/`,record).then(handleResponse).catch(handleError);
}


// Initiate Verify Email
const initiateVerifyEmail = async (record) => { 
    return await axios.post(`/api/v1/auth/verify-email/info/`, record).then(handleResponse).catch(handleError); 
}; 


// Verify Email
const verifyEmail = async (record) => { 
    return await axios.post(`/api/v1/auth/verify-email/`, record).then(handleResponse).catch(handleError); 
}; 

// Login
const login = async (record) => { 
    return await axios.post(`/api/v1/auth/login/`, record).then(handleResponse).catch(handleError); 
}; 


// Logout
const logout = async (record) => { 
    return await axios.post(`/api/v1/auth/logout/`, record).then(handleResponse).catch(handleError); 
}; 

// Forgot Password
const forgotPassword = async (record) => { 
    return axios.post(`/api/v1/auth/forgot-password/`, record).then(handleResponse).catch(handleError); 
}; 

// Reset Password
const resetPassword = async (record) => { 
    return axios.put(`/api/v1/auth/forgot-password/reset/`, record).then(handleResponse).catch(handleError); 
}; 

// Change Password
const changePassword = async (record) => { 
    return axios.put(`/api/v1/auth/change-password/`, record).then(handleResponse).catch(handleError); 
}; 

// Deactivate Account
const deactivateAccount = async (record) => { 
    return axios.delete(`/api/v1/user/deactivate/`, record).then(handleResponse).catch(handleError); 
};

// Payment Integration
const paymentIntegration = async (record) => { 
    return axios.get(`/api/v1/subscriptions/subscription_plans`).then(handleResponse).catch(handleError); 
};




// Export all functions
const AuthServices = {
    signup,
    initiateVerifyEmail,
    verifyEmail,
    login,
    logout,
    forgotPassword,
    resetPassword,
    changePassword,
    deactivateAccount,
    sendFeedback,
    paymentIntegration
};
export default AuthServices;
